import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/Ocena-Logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import { Drawer } from "@mui/material";

const Navbar = () => {
  const [menu, setMenu] = useState(false);
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setMenu(anchor);
  };
  return (
    <nav className="">
      <div className="navbar">
        <div className="navbar-section navbar-left navbar-logo-section">
          <Link to="/home">
            <img src={logo} alt="Logo" className="logo" />
          </Link>
        </div>
        <div className="navbar-section navbar-center">
          <ul className="nav-list">
            <li className="nav-item">
              <NavLink to="/home" className="nav-link">
                Home
              </NavLink>
            </li>

            <li className="nav-item">
              <NavLink to="/about" className="nav-link">
                About
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink to="/services" className="nav-link">
                Services
              </NavLink>
            </li>
            {/* <li className="nav-item">
            <NavLink to="/portfolio" className="nav-link">
            Portfolio
            </NavLink>
          </li> */}
            <li className="nav-item">
              <NavLink to="/contact-us" className="nav-link">
                Contact Us
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="navbar-section navbar-right">
          <button className="login-button">Click</button>
        </div>
        <div className="drawercontainer">
          <div
            className="hambergerMenu"
            onClick={() => {
              setMenu(true);
            }}
          >
            <MenuIcon
              sx={{
                color: "white",
                width: "43px",
                height: "32px",
                cursor: "pointer",
                background: "#1F222A",
                borderRadius: "15px",
              }}
            />
          </div>
          <Drawer
            anchor={"right"}
            open={menu}
            onClose={toggleDrawer(false)}
            PaperProps={{
              sx: {
                backgroundColor: "#1F222A",
                boxShadow: "none",
                width: "100%",
              },
            }}
          >
            <div>
              <div>
                <div className="closeiconsvg">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 15 15"
                    fill="none"
                    onClick={() => {
                      setMenu(false);
                    }}
                  >
                    <path
                      d="M13.6399 15L7.50482 8.85925L1.36977 15L0 13.6311L6.14469 7.5L0 1.36889L1.36977 0L7.50482 6.14075L13.6399 0.00964032L15 1.36889L8.86495 7.5L15 13.6311L13.6399 15Z"
                      fill="white"
                    />
                  </svg>
                </div>
                <ul className="nav-list">
                  <li className="nav-item">
                    <NavLink
                      to="/home"
                      className="nav-link"
                      onClick={() => setMenu(false)}
                    >
                      Home
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    <NavLink
                      to="/about"
                      className="nav-link"
                      onClick={() => setMenu(false)}
                    >
                      About
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/services"
                      className="nav-link"
                      onClick={() => setMenu(false)}
                    >
                      Services
                    </NavLink>
                  </li>
                  {/* <li className="nav-item">
            <NavLink to="/portfolio" className="nav-link">
            Portfolio
            </NavLink>
          </li> */}
                  <li className="nav-item">
                    <NavLink
                      to="/contact-us"
                      className="nav-link"
                      onClick={() => setMenu(false)}
                    >
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </Drawer>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
